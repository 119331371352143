import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

const RootComponent = React.lazy(() => {
  return import('./src/AppBootstrap');
});

render(
  <HelmetProvider>
    <Suspense fallback={<div />}>
      <React.StrictMode>
        <RootComponent />
      </React.StrictMode>
    </Suspense>
  </HelmetProvider>,
  document.getElementById('root'),
);
